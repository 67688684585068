<template>
    <section>
      <el-form ref="form" label-position="right" status-icon :model="form" :rules="rules" label-width="170px" style="width:95%;overflow:auto">
        <el-form-item label="设备编号" prop="id">
          <el-input placeholder="请输入设备编号" v-model="form.id" :disabled="row ? true : false"></el-input>
        </el-form-item>
        <el-form-item label="设备名称" prop="name">
          <el-input placeholder="请输入设备名称" v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="所属商户" prop="merchantId" v-if="userInfo.roleLevel < 5 && ( (row && !row.merchantId) || !row)">
          <el-select v-model="form.merchantId" placeholder="请选择所属商户">
            <el-option
              v-for="item in managerOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="制动最长运作时间(秒)" prop="brakingWheel">
          <el-input placeholder="请输入制动最长运作时间" v-model="form.brakingWheel" type="number" @input="handleInput('brakingWheel')"></el-input>
        </el-form-item>
        <el-form-item label="测速最长运作时间(秒)" prop="speedWheel">
          <el-input placeholder="请输入测速最长运作时间" v-model="form.speedWheel" type="number" @input="handleInput('speedWheel')"></el-input>
        </el-form-item>
        <el-form-item label="称重最长运作时间(秒)" prop="weighWheel">
          <el-input placeholder="请输入称重最长运作时间" v-model="form.weighWheel" type="number" @input="handleInput('weighWheel')"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="close('form')">取消</el-button>
          <el-button type="primary" @click="handleSubmit('form')" :plain='true'>立即提交</el-button>
        </el-form-item>
      </el-form>
    </section>
  </template>
  
  <script>
  import { getMerchantList,createDetectionHouse,updateDetectionHouse } from '@/api/public.js';
  import { positiveInteger } from '@/api/publicFun.js';
  export default {
    props:['row'], // 父组件携带参数
    data() {
      return {
        form:{ // 表单数据
          id: "", // 设备编号
          name:"", // 设备名称
          brakingWheel:"", // 制动最长运作时间
          speedWheel:"", // 测速最长运作时间
          weighWheel:"", // 称重最长运作时间
          merchantId:"", // 所属商户
        },
        rules: { // 表单验证
          id:[
            { required: true, message: '设备编号不得为空', trigger: 'blur',}
          ],
          name:[
            { required: true, message: '设备名称不得为空', trigger: 'blur',}
          ],
          merchantId:[
            { required:true,message:'请选择所属商户',trigger:"blur"}
          ],
          brakingWheel:[
            {required:true,message:'请输入最长运作时间',trigger:"blur"},
            { validator:positiveInteger,trigger:"blur" }
          ],
          speedWheel:[
            {required:true,message:'请输入最长运作时间',trigger:"blur"},
            { validator:positiveInteger,trigger:"blur" }
          ],
          weighWheel:[
            {required:true,message:'请输入最长运作时间',trigger:"blur"},
            { validator:positiveInteger,trigger:"blur" }
          ],
        },
        managerOptions:[], // 商户列表
        userInfo:JSON.parse(localStorage.getItem('userInfo')), // 当前登陆账号用户信息
      };
    },
  
    components: {},
  
    computed: {},
  
    mounted() {
      this.form = this.row ? {...this.row} : this.form;
      this.userInfo.roleLevel < 5 ? this.getMerchantOptions() : '';
    },
  
    methods: {
      // 数字输入框类型更改
      handleInput(value){
        this.form[value] = parseFloat(this.form[value]);
      },
      // 获取商户列表
      async getMerchantOptions(){
        let data = {
          pageNo:1,
          pageSize:1000,
          param:{
            name:'',
          }
        }
        await getMerchantList(data).then(res=>{
          if(res.success){
            this.managerOptions = res.data.list;
          }else{
            this.managerOptions = [];
          }
        })
      },
      // 关闭弹窗
      close(form){
         this.$emit('close');
      },
      // 新增
      async add(){
        await createDetectionHouse({param:this.form}).then((res) => {
          if (res.success) {
            this.$message({
              message: "提交成功",
              type: "success",
            });
            setTimeout(() => {
              this.$emit("close");
            }, 1000);
          }else{
            this.$message({
              message: res.msg,
              type: "fail",
            });
          }
        })
      },
      // 编辑
      async edit(){
        await updateDetectionHouse({param:this.form}).then((res) => {
          if (res.success) {
            this.$message({
              message: "提交成功",
              type: "success",
            });
            setTimeout(() => {
              this.$emit("close");
            }, 1000);
          }else{
            this.$message({
              message: res.msg,
              type: "fail",
            });
          }
        })
      },
      // 提交
      handleSubmit(form) {
         this.$refs["form"].validate(valid => {
            if (valid) {
                this.$confirm('确认提交吗？').then(()=>{
                  if(this.row){
                    this.edit();
                  }else{
                    this.add();
                  }
                })
            }else{
              return false;
            }
        });
      },
    }
  };
  </script>
  <style lang="scss" scoped>
  // 防止地图自动完成的对象被遮挡
  .tangram-suggestion {
      z-index: 9999 !important;
    }
   .el-drawer{
     overflow:auto !important;
   }
   /deep/ .el-form-item__content{
    display: flex;
   }
   .map{
    border: 1px solid #dcdfe6;
    background: #fff;
    border-radius: 4px;
    width: 80px;
   }
   .avatar-uploader .el-upload {
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
      border-color: #409EFF;
    }
    .avatar-uploader-icon {
      border: 1px dashed #ccc;
      border-radius: 50%;
      overflow:hidden;
      font-size: 28px;
      color: #8c939d;
      width: 178px;
      height: 178px;
      line-height: 178px;
      text-align: center;
    }
    .avatar {
      width: 178px;
      height: 178px;
      display: block;
    }
  </style>
  