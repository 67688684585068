<template>
  <section class="outContainer">
    <!-- 搜索栏 -->
    <div class="search">
      <el-input class="each"  placeholder="设备编号" v-model="searchInfo.param.id"></el-input>
      <el-input class="each"  placeholder="设备名称" v-model="searchInfo.param.name"></el-input>
      <el-select placeholder="在线状态" class='each' v-model="searchInfo.param.status" clearable>
        <el-option
          v-for="item in statusOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
      <el-select class="each" v-model="searchInfo.param.merchantId" placeholder="所属商户"  v-if="userInfo.roleLevel < 5">
        <el-option
          v-for="item in managerOptions"
          :key="item.id"
          :label="item.name"
          :value="item.id">
        </el-option>
      </el-select>
      <el-button size="mini" type="primary" icon="el-icon-search" @click="handleSearch('search')">搜索</el-button>
      <el-button size="mini" type="primary" icon="el-icon-close" @click="handleSearch('clear')">清空搜索条件</el-button>
      <el-button size="mini" @click="openDrawer(null)">新增</el-button>
    </div>
    <!-- 数据表格 -->
    <div class="tableContainer">
      <el-table
       :data="tableList"
       height="100%">
       <el-table-column align="center" label="设备编号" prop="id">
       </el-table-column>
       <el-table-column align="center" label="设备名称" prop="name">
       </el-table-column>
       <el-table-column align="center" label="在线状态" prop="statusStr">
       </el-table-column>
       <el-table-column align="center" label="工作状态" prop="workStatusStr">
       </el-table-column>
       <el-table-column align="center" label="版本" prop="version">
        <template slot-scope="scope">
          <p>硬件版本：{{ scope.row.hardWareVersion ? scope.row.hardWareVersion : '--' }}</p>
          <p>软件版本：{{ scope.row.version ? scope.row.version : '--' }}</p>
        </template>
       </el-table-column>
       <el-table-column align="center" label="检测初始值" prop="maxTime">
        <template slot-scope="scope">
          <!-- <p>整车质量：{{ scope.row.weight || scope.row.weight == 0 ? scope.row.weight + 'KG' : '--' }}</p> -->
          <p>轴重：{{ scope.row.axleWeight || scope.row.axleWeight == 0 ? scope.row.axleWeight + 'KG' : '--' }}</p>
          <p>制动力：{{ scope.row.braking || scope.row.braking == 0 ? scope.row.braking + 'KG' : '--' }}</p>
        </template>
       </el-table-column>
       <el-table-column align="center" label="检测最长运作时间" prop="maxTime">
        <template slot-scope="scope">
          <p>称重：{{ scope.row.weighWheel ? scope.row.weighWheel + '秒' : '--' }}</p>
          <p>测速：{{ scope.row.speedWheel ? scope.row.speedWheel + '秒' : '--' }}</p>
          <p>制动：{{ scope.row.brakingWheel ? scope.row.brakingWheel + '秒' : '--' }}</p>
        </template>
       </el-table-column>
       <el-table-column align="center" label="创建时间" prop="createTimeStr">
       </el-table-column>
       <el-table-column align="center" label="操作" fixed="right" width="180">
        <template slot-scope="scope">
          <el-button size="mini" @click="handleInit(scope.row)">初始化</el-button>
          <el-popover placement="top" trigger="click" width="auto">
              <div class="moreBtn">
                <span class="spanBtn" @click="openDrawer(scope.row)">编辑</span>
                <span class="spanBtn" @click="handleDel(scope.row)">删除</span>
                <!-- <span class="spanBtn" @click="handleDisposition(scope.row,'set')">配置</span>
                <span class="spanBtn" @click="handleDisposition(scope.row,'rollback')">配置回滚</span> -->
              </div>
              <el-button style="margin-left: 10px;" slot="reference" size="mini">更多</el-button>
          </el-popover>
        </template>
       </el-table-column>
      </el-table>
    </div>
    <!-- 分页 -->
    <Pagination v-bind:child-msg="searchInfo" @callFather="callFather"></Pagination>
    <!-- 新增、编辑 -->
    <el-drawer :title="row ? '编辑' : '新增'" :visible.sync="addDrawer" size="50%">
      <add v-if="addDrawer" @close="handleClose" ref='newForm' :row="row"></add>
    </el-drawer>
    <!-- 配置 -->
    <el-dialog title="检测最长运作时间" :visible.sync="dispositionDialog" :close-on-click-modal="false" append-to-body>
      <el-form ref="dispositionInfo" :model="dispositionInfo" :rules="dispositionRules" label-width="180px">
        <el-form-item label="设备编号">
          <el-input v-model="dispositionInfo.id" disabled></el-input>
        </el-form-item>
        <el-form-item label="称重（秒）" prop="weighWheel">
          <el-input type="number" placeholder="请输入称重最长运作时间" v-model="dispositionInfo.weighWheel"></el-input>
        </el-form-item>
        <el-form-item label="测速（秒）" prop="speedWheel">
          <el-input type="number" placeholder="请输入测速最长运作时间" v-model="dispositionInfo.speedWheel"></el-input>
        </el-form-item>
        <el-form-item label="制动（秒）" prop="brakingWheel">
          <el-input type="number" placeholder="请输入制动最长运作时间" v-model="dispositionInfo.brakingWheel"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dispositionDialog = false">关闭</el-button>
        <el-button @click="confirmDisposition('dispositionInfo')">提交</el-button>
      </div>
    </el-dialog>
  </section>
</template>

<script>
import Pagination from '@/components/Pagination.vue';
import add from './add.vue';
import { timeChange } from '@/api/publicFun.js';
import { getDetectionHouseList,getMerchantList,deleteDetectionHouse,initDetectionHouse,dispositionDetectionHouse } from '@/api/public.js';
import { positiveInteger } from '@/api/publicFun.js';
export default {
  data() {
    return {
      tableList:[], // 表格数据
      searchInfo:{ // 筛选条件
        pageNo:1,
        pageSize:10,
        param:{
          id:"",
          merchantId:"",
          name:"",
          status:"",
        },
        total:0,
      },
      statusOptions:[ // 在线状态选项
        {value:1,label:'在线'},
        {value:2,label:'离线'},
      ],
      addDrawer:false, // 控制新增弹框是否显示
      row:null, // 当前操作表格列表数据
      dispositionInfo:{ // 配置信息
        id:"",
        timeout:"",
      },
      dispositionRules:{ // 配置信息表单校验
        weighWheel:[
          { required: true, message: '称重最长运作时间不得为空', trigger: 'blur',},
          { required: true, validator: positiveInteger, trigger: 'blur' }
        ],
        speedWheel:[
          { required: true, message: '测速最长运作时间不得为空', trigger: 'blur',},
          { required: true, validator: positiveInteger, trigger: 'blur' }
        ],
        brakingWheel:[
          { required: true, message: '制动最长运作时间不得为空', trigger: 'blur',},
          { required: true, validator: positiveInteger, trigger: 'blur' }
        ]
      },
      dispositionDialog:false, // 控制配置弹框是否显示
      userInfo:JSON.parse(localStorage.getItem('userInfo')),
      managerOptions:[], // 商户选项
    }
  },

  components: {
    Pagination,
    add
  },

  computed: {},

  mounted() {
    this.init();
    this.userInfo.roleLevel < 5 ? this.getMerchantOptions() : '';
  },

  methods: {
    // 搜索
    handleSearch(type){
      this.searchInfo.pageNo = 1;
      if(type == 'clear'){
        this.searchInfo.param = {
          id:"",
          merchantId:"",
          name:"",
          status:"",
        }
      }
      this.init();
    },
    // 获取商户列表
    async getMerchantOptions(){
      let data = {
        pageNo:1,
        pageSize:1000,
        param:{
          name:'',
        }
      }
      await getMerchantList(data).then(res=>{
        if(res.success){
          this.managerOptions = res.data.list;
        }else{
          this.managerOptions = [];
        }
      })
    },
    // 获取表格列表数据
    async init(){
      await getDetectionHouseList(this.searchInfo).then(res => {
        if(res.success){
          this.tableList = res.data.list;
          this.searchInfo.total = res.data.total;
          this.tableList.forEach(element => {
            element.createTimeStr = element.createTime ? timeChange(element.createTime,'seconds') : '--';
          });
        }else{
          this.tableList = [];
          this.searchInfo.total = 0;
          this.$message({
            message:res.msg,
            type:'fail'
          })
        }
      })
    },
    // 删除
    handleDel(row){
      this.$confirm('确认执行该操作吗？').then(async ()=>{
        await deleteDetectionHouse({param:row.id}).then(res=>{
          this.$message({
            message:res.success ? '操作成功' : res.msg,
            type:res.success ? 'success' : 'fail'
          })
          this.init();
        })
      })
    },
    // 打开侧边弹框
    openDrawer(row){
      this.row = row ? {...row} : row;
      this.addDrawer = true;
    },
    // 关闭侧边弹窗
    handleClose(){
      this.addDrawer = false;
      this.init();
    },
    // 设备初始化
    handleInit(row){
      this.$confirm('确认进行该操作吗？').then(()=>{
        initDetectionHouse({param:row.id}).then(res=>{
          this.$message({
            message:res.success ? '指令下发成功' : res.msg,
            type:res.success ? 'success' : 'fail'
          })
          this.init();
        })
      })
    },
    // 打开配置弹框
    disposition(row){
      this.dispositionInfo = {...row};
      this.dispositionDialog = true;
    },
    // 确认提交配置
    confirmDisposition(form){
      this.$refs["dispositionInfo"].validate(valid => {
        if(valid){
          this.$confirm('确认进行该操作吗？').then(()=>{
            this.$message({
              message:'提交成功',
              type:'success',
            })
            this.dispositionDialog = false;
          })
        }
      })
    },
    // 配置、回滚操作
    handleDisposition(row,type){
      this.$confirm('确定要继续此操作吗？请注意，此操作需由专业技术人员在特定设备上执行一系列专业步骤方可生效，以确保系统的安全与稳定。').then(async ()=>{
        const loading = this.$loading({
            lock: true,
            text: '指令下发中',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
        });
        let data = {
          param:{
            dispositionType:type,
            testingRoomId:row.id,
          }
        }
        await dispositionDetectionHouse(data).then(res => {
          if(res.success){
            this.$message({
              message:res.data == 1 ? '指令下发超时' : (res.data == 2 ? '指令下发成功' : '指令下发失败'),
              type:res.data == 2 ? 'success' : 'fail',
            })
          }else{
            this.$message({
              message:res.msg,
              type:'fail',
            })
          }
          loading.close();
          this.init();
        })
      })
    },
    // 分页操作事件
    callFather(parm){
      this.searchInfo.pageNo = parm.currentPage;
      this.init();
    },
  },
};
</script>
<style scoped lang="scss">
.moreBtn{
  text-align: right;
  margin: 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.spanBtn{
  width: 100%;
  cursor: pointer;
  display: inline-block;
  color: #40a9ff;
  text-align: center;
  padding-bottom: 5px;
}
</style>
